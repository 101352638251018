import React from "react";
import Modal from "react-modal";

//@ts-ignore
import Icons from "../../assets/images/icons.svg"

type Props = {
  isOpen: boolean;
  onRequestClose?(): any;
  identificador: string;
}

//https://reactcommunity.org/react-modal/styles/
const customStyles = {
  content: {
    width: '50%',
    height: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.65)',
  }
}

export function AcessibilidadeModal({
  isOpen,
  onRequestClose,
  identificador
}: Props): JSX.Element {

  const linkSolicitacaoPCD =
  identificador === 'baladapp'
    ? "https://atendimento.baladapp.com.br/hc/pt-br/requests/new?ticket_form_id=25562267835547"
    : identificador === 'bemingressos'
    ? "https://atendimento.bemingressos.com.br/hc/pt-br/requests/new?ticket_form_id=34223955954587"
    : "https://atendimento.baladapp.com.br/hc/pt-br/requests/new?ticket_form_id=25562267835547";

  return (
    <Modal
      onRequestClose={onRequestClose}
      isOpen={isOpen}
      style={customStyles}
      ariaHideApp={false}
      className={"modal-info"}
    >
      <div className="baladapp-modal-texto">
        <div className="title">
          <h2>Lei de Acessibilidade Estadual (AM) nº 241 de 27 março de 2015 adverte:</h2>
          <a className="fechar" onClick={onRequestClose}>
            <svg>
              <use href={`${Icons}#close`}></use>
            </svg>
          </a>
        </div>

        <div className="body">
          <p>
              (...)
          </p><br/>
          <p>
              Art. 16. Fica instituída a gratuidade para pessoas com deficiência e meia-entrada para seu acompanhante nos
              eventos, em salas de cinema, em espetáculos de teatro e circo, em museus, parques e eventos educativos,
              esportivos, de lazer, culturais e similares.
          </p>
          <p><br/>
              (...)
          </p><br/>
          <p>
              § 1º A meia-entrada do acompanhante corresponde a 50% (cinquenta por cento) do valor do ingresso cobrado,
              sem restrição de data e horário.
          </p>
          <p>
              § 4º O Poder Público ficará responsável pela emissão da carteira de identificação que garante a gratuidade
              bem como sua regulamentação.
          </p>
          <p><br/>
              (...)
          </p><br/>
          <p>
              Art. 17. Os locais, mencionados nos artigos 13, 15 e 16, são obrigados a reservar, no mínimo, 10% (dez por
              cento) de seus lugares e assentos, devendo estarem distribuídos pelo recinto em locais diversos, de boa
              visibilidade, próximo aos corredores, devidamente sinalizados, evitando-se áreas segregadas do público e a
              obstrução das saídas, em conformidade com as Normas Técnicas de Acessibilidade da ABNT, para as pessoas com
              deficiência ou com mobilidade reduzida, definidas nesta Lei, ficando o mesmo direito estendido ao seu
              acompanhante.
          </p>
          <p><br/>
              (...)
          </p><br/>
          <p> 
              <a href={linkSolicitacaoPCD}
                  target="_blank">Para <b>solicitação</b> de ingressos, online, para os beneficiários dessa lei clique
                  aqui.</a>
          </p>
          <p><br/>
              <i>Observação: será feito um cadastro prévio e análise da documentação apresentada, com prazo médio de 24
                  horas úteis para o envio dos ingressos.</i>
          </p>
          <b>Se não quiser aguardar, os ingressos podem ser retirados nos pontos de venda físicos.</b>
        </div>

      </div>


    </Modal>
  )
}