import ReactOnRails from 'react-on-rails';
import Modal from 'react-modal';

import {
    AddLastSeenAnuncioContainer,
    AnunciosListContainer,
    BotaoPontoDeVendasContainer,
    AnunciosListHotsiteContainer,
    LocationIndicatorContainer,
    BannersContainer,
    AutenticationNavBarContainer,
    SearchBarContainer,
    BotaoAcessibilidadeContainer,
    AberturaVendasContainer,
    CookiesContainer,
    MenuLateralAnuncioContainer,
    BtnComprarContainer,
    BtnLojaContainer,
    LastSeenAnunciosContainer

} from "containers";

Modal.setAppElement(document.getElementsByTagName('body')[0]);

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
    AddLastSeenAnuncioContainer,
    AnunciosListContainer,
    AutenticationNavBarContainer,
    BannersContainer,
    LastSeenAnunciosContainer,
    LocationIndicatorContainer,
    SearchBarContainer,
    BotaoAcessibilidadeContainer,
    BotaoPontoDeVendasContainer,
    AberturaVendasContainer,
    CookiesContainer,
    MenuLateralAnuncioContainer,
    AnunciosListHotsiteContainer,
    BtnComprarContainer,
    BtnLojaContainer
});
