import React from "react";
import { ContainerBase } from "containers/ContainerBase";
import { BotaoAcessibilidade } from "../components/botao-acessibilidade/BotaoAcessibilidade";

// type Props = {
//   identificador: string;
// };

export function BotaoAcessibilidadeContainer({ identificador }: any, railsContext: any) {
  return () => {
    return (
      <ContainerBase railsContext={railsContext}>
        <BotaoAcessibilidade identificador={identificador} />
      </ContainerBase>
    );
  }
}