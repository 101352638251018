import React, { useRef } from "react";
import userTimer from "../../src/hooks/useTimer.web";
import { setTimeout } from 'worker-timers';
import './CronometroAberturaVendas.scss'
import { useTranslationWeb } from "src/common/i18n";

type Props = {
  dataAbertura: number;
}

export function CronometroAberturaVendas({ dataAbertura }: Props) {
  let secondsRest: number = dataAbertura - (new Date().getTime() / 1000)
  let isShowTime: boolean = secondsRest > 0

  const { t } = useTranslationWeb();

  const timeoutRef = useRef(0)

  const onTimerOver = () => {
    if (!isShowTime && (timeoutRef.current == 0)) {
      let secondsUntilRefresh: number = ((Math.floor(Math.random() * (20 - 5 + 1)) + 5) * 1000);

      const timeout = setTimeout(() => {
        window.location.reload();
      }, secondsUntilRefresh);

      timeoutRef.current = timeout
    }
  }

  const { periodObj } = userTimer({ timerInSeconds: secondsRest, secondsLeft: secondsRest, onTimerOver: onTimerOver })

  const renderVendasAbertas = () => {
    return (
      <div className="redirecionando">
        <span> {t('redirecionando_segundos')} </span>
        <a href=""> {t('clique_aqui')} </a>.
      </div>
    )
  }


  const renderTimer = () => {
    return (
      <div className="liberacao-venda">
        <h4>{t('abertura_de_vendas_em')} </h4>
        <div className="cronometro">
          <ul>
            {periodObj.dias >= 0 &&
              <li>
                <p>{periodObj.dias.toString().padStart(2, '0')}</p>{t('dia')}{periodObj.dias != 1 && 's'}
              </li>
            }

            {periodObj.dias >= 0 && <li className="colon">:</li>}

            {periodObj.horas >= 0 &&
              <li>
                <p>{periodObj.horas.toString().padStart(2, '0')}</p>{t('hora')}{periodObj.horas != 1 && 's'}
              </li>
            }

            {periodObj.horas >= 0 && <li className="colon">:</li>}

            {periodObj.minutos >= 0 &&
              <li>
                <p>{periodObj.minutos.toString().padStart(2, '0')}</p>{t('minuto')}{periodObj.minutos != 1 && 's'}
              </li>
            }

            {periodObj.minutos >= 0 && <li className="colon">:</li>}


            {periodObj.segundos >= 0 &&
              <li>
                <p>{periodObj.segundos.toString().padStart(2, '0')}</p>{t('segundo')}{periodObj.segundos != 1 && 's'}
              </li>
            }
          </ul>
        </div>
      </div>
    )
  }

  return (
    isShowTime ? renderTimer() : renderVendasAbertas()
  )
}

