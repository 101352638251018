import React from "react";
import { ContainerBase } from "containers/ContainerBase";
import { CronometroAberturaVendas } from "../../../baladapp-react-components/src/cronometro-abertura-vendas/CronometroAberturaVendas.web";

// type Props = {
//     dataAbertura?: string;
// }

export function AberturaVendasContainer(props: any, railsContext: any) {
    return () => {
        return (
            <ContainerBase railsContext={railsContext}>
                <CronometroAberturaVendas dataAbertura={props.dataAbertura} />
            </ContainerBase>
        )
    }
}