import React from "react";
import { ContainerBase } from "containers/ContainerBase";
import { SearchBar } from "../components/search-bar/SearchBar/SearchBar";

// type Props = {
//     termoBusca?: string;
//     style?: string;
// }

export function SearchBarContainer(props: any, railsContext: any) {
    return () => {
        return (
            <ContainerBase railsContext={railsContext}>
                <SearchBar termoBusca={props?.termoBusca} style={props?.style} />
            </ContainerBase>
        )
    }
}