import React from "react";
import { ContainerBase } from "containers/ContainerBase";
import { BotaoLoja } from "components/botao-loja/BotaoLoja";

// type Props = {
//   anuncioId: number;
//   paramsIndicacao?: string;
//   url: string;
//   texto: string;
// }
export function BtnLojaContainer({ anuncioId, paramsIndicacao, url, texto }: any, railsContext: any) {
  return () => {
    return (
      <ContainerBase railsContext={railsContext}>
        <BotaoLoja texto={texto} anuncioId={anuncioId} paramsIndicacao={paramsIndicacao} url={url} />
      </ContainerBase>
    )
  }
}