import React from "react";

import { flushExpired, set, get } from "lscache"
// import { LojaIcon } from "../../../../baladapp-react-components/src/assets/LojaIcon.web";


type Props = {
	anuncioId: number;
	paramsIndicacao?: string;
	url: string;
	texto: string;
}


export const BotaoLoja = ({anuncioId, paramsIndicacao, url, texto}: Props) => {

	// remove storage expiradas
	flushExpired();

	let nomeStorage = `cod_indicacao_${anuncioId}`

	if (paramsIndicacao) {
			set(nomeStorage, paramsIndicacao, 24 * 60)
	}

	const newUrl = new URL(url)

	if (!!get(nomeStorage) && !paramsIndicacao) {
		newUrl.searchParams.set('indicacao', get(nomeStorage))
	}

	return (
			<a className="btn-loja" href={newUrl.toString()} >
				{/* <LojaIcon width={22} heigth={20} /> */}
				{texto}
			</a>
	)
}