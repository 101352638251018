import React, { useCallback, useContext, useEffect, useState } from "react";

import translationPt from "./locales/pt/translation.json";
import translationEn from "./locales/en/translation.json";
import translationEs from "./locales/es/translation.json";
import { defaultLanguage, supportedLanguages } from "../constants";

export interface TranslationContextInterface {
  locale: string;
}

const initialState: TranslationContextInterface = {
  locale: 'pt-br',
}

export const TranslationContext = React.createContext<TranslationContextInterface>(initialState);

export interface TranslationProviderProps extends TranslationContextInterface {
  children: React.ReactNode;
}

function getLanguage(newLocale: string) {
  if (!newLocale) {
    console.warn(`Idioma não especificado. Utilizando idioma padrão.`);
    return defaultLanguage;
  }

  newLocale = newLocale.toLowerCase();

  const language = supportedLanguages.find((lang) => {
    return (lang == newLocale || lang.split('-')[0] == newLocale);
  })

  //@ts-ignore
  if (language) {
    return language;
  }

  console.warn(`idioma ${newLocale} não é suportado. Apenas ${supportedLanguages} são suportados`);
  return defaultLanguage;
}

const translations: Record<string, Record<string, string>> = {
  'pt-br': translationPt,
  'en-us': translationEn,
  'es-es': translationEs,
}

export default function TranslationsProviderWeb({ children, locale: localeProp }: TranslationProviderProps) {
  const [locale, setLocale] = useState<typeof supportedLanguages[number]>(getLanguage(localeProp));

  const onChangeLocale = useCallback((newLocale: string) => {
    setLocale(getLanguage(newLocale));
  }, [setLocale]);

  useEffect(() => {
    onChangeLocale(localeProp);
  }, [localeProp]);


  return (
    <TranslationContext.Provider value={{ locale }}>
      {children}
    </TranslationContext.Provider>
  )
}

export function useTranslationWeb() {
  const values = useContext(TranslationContext);

  const t = useCallback((key: string) => {
    return translations[values.locale][key];
  }, [values.locale]);

  return {
    ...values,
    t
  }
}