import React from "react";
import { ContainerBase } from "containers/ContainerBase";
import { Cookies } from "../components/cookies/Cookies.web.tsx";

// type Props = {
//     AuthUrl?: string;
// }

export function CookiesContainer(props: any, railsContext: any) {
    return () => {
        return (
            <ContainerBase railsContext={railsContext}>
                <Cookies AuthUrl={props.AuthUrl} />
            </ContainerBase>
        )
    }
}