import { AddLastSeenAnuncio } from "components/last-seen-anuncios/AddLastSeenAnuncio";
import React from "react";
import { ContainerBase } from "./ContainerBase";

// type Props = {
//     anuncioId?: number;
// }

const AddLastSeenAnuncioContainer = (props: any, railsContext: any) => (
    () => {
        return (
            <ContainerBase railsContext={railsContext}>
                <AddLastSeenAnuncio anuncioId={props.anuncioId} />
            </ContainerBase>
        )
    }
)

export { AddLastSeenAnuncioContainer }